<template>
  <div>
    <a-table
      :columns="license.columns"
      :dataSource="license.dataSource"
      :loading="ui.loading"
      rowKey="id"
    ></a-table>
  </div>
</template>

<script>
import { getLicenseList } from "@/api/company";

const licenseColumns = [
  {
    title: "编号",
    dataIndex: "id",
  },
  {
    title: "发证机构",
    dataIndex: "issueOrganization",
  },
  {
    title: "证件类型",
    dataIndex: "type",
  },
];

export default {
  data() {
    return {
      license: {
        columns: licenseColumns,
        dataSource: [],
        info: {},
      },
      ui: {},
    };
  },
  created() {
    this.companyId = this.$route.params.companyId;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.ui.loading = true;
      getLicenseList({
        companyId: this.companyId,
      }).then((res) => {
        this.ui.loading = false;
        this.license.dataSource = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>